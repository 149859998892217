.Main{
    display: grid;
}
.target{
    display: grid;
    text-align: center;
}

.target img{
    margin-left: 100px;
    width: 100%;
    height: 80%;
}