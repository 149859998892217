.table1, .table2{
    width: 100%;
    border: 5px solid black;
    text-align: center;
    border-collapse: collapse;
}
.thesis th, td{
    border: 1px solid black;
}
.table1{
    background-color:lightcyan;
}
.table2{
    margin-top: 50px;
    margin-bottom: 100px;
}
.thesis{
    display: grid;
}