.Info{
    display: grid;
}
.Info article{
    width: 100%;
}
.Info img{
    width: auto;
    margin-left: 20px;
}
.Intro{
    margin-top: 50px;
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 50px;
    margin-bottom: 100px;
    margin-bottom: 100px;
    margin-top: -20px;
    font-weight: bold;
}
.IntroImg{
    width: 100%;
    display: grid;
    grid-template-columns: 40% 40%;
    
    justify-content: center;
    text-align: center;
}
.Outline{
    margin-top: 3px;
}
.Vision{
    margin-left: 5%;
    
}
.Vision p{
    margin-left: 3em;
}
.Info p{
    font-weight: bold;
    
}