.Main nav{
    margin-top: 15px;
}
body{
    font-family: 'paybooc-Medium';
    width: 100%;
    align-items: center;
    display: grid;
    grid-template-columns: 80%;
    justify-content: center;

}
pre{
    font-family: 'paybooc-Medium';
    font-weight: bold;
    white-space: pre-wrap;
    line-height: 35px;
}
@font-face {
    font-family: 'paybooc-Medium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/paybooc-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.Main img{
    width: auto;
    height: 835px;
    display: grid;
    margin: auto;
}

