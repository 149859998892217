.Nav{
    margin: 10px;
    /* background-color: #BBBBBB; */
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 100%;
    
}
.Nav Button{
    margin-left: 15px;
    margin-right: 100px;
    background-color: transparent;
    border: 0;
    font-size: 20px;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    font-family: 'paybooc-Medium';
    font-weight: bold;
    cursor: pointer;
}
hr{
    width: 400%;
    margin-bottom: -10px;
}
